<template>
  <div class="login-container">
    <div class="login-box">
      <div class="logo">
        <img src="@/assets/images/logo.jpg" alt="">
      </div>
      <div class="name">
        <h2>智慧数字门店</h2>
      </div>
      <el-form ref="loginFormRef" :rules="loginFormRules" :model="loginForm">
        <el-form-item prop="account">
          <el-input class="input" clearable placeholder="请输入用户名" v-model="loginForm.account" type="text"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input class="input" clearable placeholder="请输入登录密码" v-model="loginForm.password" type="password">
          </el-input>
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="login">登录</el-button>
      <el-tooltip class="text" placement="top">
        <div slot="content">请联系客服重置登录密码<br />电话：400-4000-4004</div>
        <el-button>忘记密码</el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loginForm: {
          account: '',
          password: ''
        },
        // 用户名&密码验证规则
        loginFormRules: {
          account: [{
              required: true,
              message: '请输入登录名称',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 10,
              message: '长度在 3 到 10 个字符',
              trigger: 'blur'
            }
          ],
          password: [{
              required: true,
              message: '请输入登录密码',
              trigger: 'blur'
            },
            {
              min: 6,
              max: 16,
              message: '长度在 6 到 16 个字符',
              trigger: 'blur'
            }
          ]
        }
      }
    },
    methods: {
      login() {
        let loading = this.$loading({
          lock: false,
          text: '登录中...',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        if (!this.loginForm.account || !this.loginForm.password) {
            this.$message.error('用户名或密码不能为空！！');
            loading.close()
          } else {
            this.$http.post('api/store/login/login',this.loginForm).then(res =>{
              // console.log(res)
              this.$store.commit('setToken',res.data.data)
              this.$message.success(res.data.msg)
              this.$router.push({
                path:'/work'
              })
              loading.close()
            }).catch(err =>{
              loading.close()
            })
          }
      }
    }
  }

</script>

<style lang="less" scoped>
  .login-container {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/login-bg.png');
    background-repeat: no-repeat;

    .login-box {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 360px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 8px #666;
      padding: 30px 20px 0;

      .logo {
        margin: auto;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        overflow: hidden;

        img {
          width: 100px;
        }
      }

      .name {
        text-align: center;
        margin: 10px;
        color: #333;
      }

      .input {
        margin: 10px 0;
      }

      .btn {
        width: 100%;
        margin: 10px 0;

      }

      .text {
        font-size: 12px;
        color: #409eff;
        float: right;
        margin-bottom: 10px;
      }

      overflow: hidden;

    }
  }


  /deep/ .btn.el-button {
    &:hover {
      background-color: #409eff;
    }
  }

  /deep/ .text.el-button {
    border: 0 !important;

    &:hover {
      background-color: #fff;
    }
  }

</style>
